<template>
  <div>
    <div>
      <main class="page-content">
        <div class="account-page-area vendordashboard">
          <div class="container containerDash">
            <div class="row">
              <div class="col-lg-3 account_border-right">
                <sidebar-dashboard></sidebar-dashboard>
              </div>

              <!-- ///// -->
              <div class="col-lg-9">
                <h3>Pending Payment</h3>
                <div v-if="!customerList.length">
                  <notfound :checkdata="initialLoading"></notfound>
                </div>
                <!-- <div> -->
                <div v-else>
                  <b-table
                    :items="customerList"
                    :fields="fields"
                    select-mode="multi"
                    responsive
                    stacked="sm"
                    ref="selectableTable"
                    selectable
                    @row-selected="onRowSelected"
                    disabled
                  >
                    <!-- Example scoped slot for select state illustrative purposes -->
                    <template #cell(selected)="{ rowSelected }">
                      <template v-if="rowSelected">
                        <span aria-hidden="true"
                          ><i class="fas fa-check-square"></i
                        ></span>
                        <!-- <input
                          type="checkbox"
                          name=""
                          id=""
                          :checked="isChecked"
                         
                        /> -->
                        <span class="sr-only">Selected</span>
                      </template>
                      <template v-else>
                        <span aria-hidden="true"
                          ><i class="far fa-square"></i
                        ></span>
                        <!-- <input
                          type="checkbox"
                          name=""
                          id=""
                          :checked="isChecked"
                        /> -->

                        <span class="sr-only">Not selected</span>
                      </template>
                    </template>
                  </b-table>
                  <div class="row">
                    <div class="col-md-2">
                      <b-button
                        class="btn btn-success sel-all-three"
                        size="sm"
                        style="width: 115px"
                        @click="selectAllRows"
                        >Select all</b-button
                      >
                    </div>
                    <div class="col-md-2">
                      <b-button
                        class="btn btn-warning clr-sel-three"
                        size="sm"
                        style="width: 115px"
                        @click="clearSelected"
                        >Clear selected</b-button
                      >
                    </div>
                    <div class="col-md-8" style="text-align: end">
                      <b-button
                        class="btn btn-primary pay-com-three"
                        size="sm"
                        style="width: 115px"
                        @click="payCommission()"
                        >Pay Commission
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>

              <!-- ///// -->
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import userService from "@/services/user.service";
import Config from "@/config";
export default {
  data() {
    return {
      paymentInfo: "",
      script: `https://checkout.razorpay.com/v1/checkout.js`,
      boxTwo: "",
      customerList: "",
      path: Config.BASE_URL_IMG,
      initialLoading: true,
      //  { key: 'value', label: 'value(R$)', sortable: true,
      //         formatter: (value, key, item) => value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})
      //     },
      fields: [
        { key: "aucid", label: "Id" },
        { key: "aucName", label: "Auction Name" },
        {
          key: "myBidAmount",
          label: "Win Amount",
          formatter: (myBidAmount) =>
            myBidAmount.toLocaleString("en-IN", {
              style: "currency",
              currency: "INR",
            }),
        },
        {
          key: "tokenamount",
          label: "Commission",
          formatter: (tokenamount) =>
            tokenamount.toLocaleString("en-IN", {
              style: "currency",
              currency: "INR",
            }),
        },
        {
          key: "balance",
          label: "Balance",
          formatter: (balance) =>
            balance.toLocaleString("en-IN", {
              style: "currency",
              currency: "INR",
            }),
        },
        "selected",
      ],
      isChecked: "",
      selected: [],
      aucIds: "",
      message: "",
      // sum_Of_Commission: null,
    };
  },
  mounted() {
    if (this.$store.state.auth.user) {
      this.st = this.$store.state.auth.user[0];

      this.getPaymentHistory(this.st.sellerId);
    } else {
      this.$router.push("/").catch(() => {
        "myaccount";
      });
    }
  },
  methods: {
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
      // this.isChecked = true;
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
      this.selected = [];
      // this.isChecked = false;
    },
    // payCommission() {
    //   console.log(this.selected);

    // },

    payCommission() {
      if (this.selected.length > 0) {
        var sum_Of_Commission = 0;
        this.aucIds = [];
        for (let i = 0; i < this.selected.length; i++) {
          const element = this.selected[i];
          sum_Of_Commission += parseFloat(element.tokenamount);
          this.aucIds += element.aucid + ",";
        }

        this.boxTwo = "";
        this.$bvModal
          .msgBoxConfirm(
            "Please confirm that you want to pay commission of RS " +
              sum_Of_Commission +
              ".",
            {
              title: "Please Confirm",
              size: "sm",
              buttonSize: "sm",
              okVariant: "danger",
              okTitle: "Confirm",
              cancelTitle: "Cancel",
              footerClass: "p-2",
              hideHeaderClose: false,
              centered: true,
            }
          )
          .then((value) => {
            this.boxTwo = value;
            if (this.boxTwo == true) {
              //  console.log(sum_Of_Commission,this.aucIds);

              this.purchase(sum_Of_Commission, this.aucIds);
            }
          })
          .catch((err) => {
            // An error occurred
            console.log(err);
          });
      } else {
        this.makeToast("warning", "Please select pending payments");
      }
    },
    makeToast(variant, body_content) {
      let tittleMessage = variant;
      if (variant == "danger") {
        tittleMessage = "Error";
      }
      if (variant == "success") {
        tittleMessage = "Success";
      }
      if (variant == "warning") {
        tittleMessage = "Warning";
      }

      this.$bvToast.toast(body_content, {
        title: `${tittleMessage || "default"}`,
        variant: variant,
        autoHideDelay: 5000,
        //solid: true,
        toaster: "b-toaster-bottom-right",
        appendToast: true,
      });
    },

    purchase(sum_Of_Commission, auids) {
      this.st = this.$store.state.auth.user;
      let cusid = this.st[0].sellerId;
      userService
        .payCommissions(sum_Of_Commission, cusid, auids)
        .then((response) => {
          this.paymentInfo = response.data.Data[0];
          this.message = "";
          //  console.log(response.data.Data[0]);
          this.message = response.data.Message;

          if (this.message == "Success") {
            this.makeToast("success", "Payment Successfull");

            this.$router.push("/PaymentHistory").catch(() => {
              "onpaymentSuccess";
            });
          }

          // if (this.message == "Success" && this.paymentInfo.rp_token != null) {
          //   // alert("Success");
          //   //  let rpayTokenId =  JSON.parse(this.paymentInfo.rp_token);
          //   //console.log();

          //   const options = {
          //     key: Config.rpayKey,
          //     amount: this.paymentInfo.commissionAmount,
          //     currency: Config.curency,
          //     name: this.paymentInfo.sellerName,
          //     description: "Commission",
          //     order_id: this.paymentInfo.rp_token,
          //     image: `/assets/images/menu/logo/2.png`,
          //     handler: function (response) {
          //       // console.log(response.razorpay_payment_id);
          //       //   console.log(response.razorpay_order_id);
          //       //   console.log(response.razorpay_signature);
          //       // It is function. executed on success
          //       userService
          //         .updCommissionPaymentHistory(response)
          //         .then((response) => {
          //           this.message = response.data.Message;
          //           if (this.message == "Success") {
          //             //location.reload();
          //             self.$router.push("/PaymentHistory").catch(() => {
          //               "onpaymentSuccess";
          //             });
          //           }
          //         })
          //         .catch((error) => {
          //           //alert('hi');
          //           this.isLoading = false;
          //           this.message =
          //             (error.response && error.response.data.Message) ||
          //             error.message ||
          //             error.toString();
          //         });
          //     },

          //     prefill: {
          //       name: this.paymentInfo.sellerName,
          //       email: this.paymentInfo.sellerEmail,
          //       contact: this.paymentInfo.sellerPhone,
          //     },
          //     theme: {
          //       color: "#3399cc",
          //     },
          //   };

          //   const rzp1 = new window.Razorpay(options);
          //   rzp1.on("payment.failed", function (response) {
          //     console.log(response.error.code);
          //     // alert(response.error.description);
          //     // alert(response.error.source);
          //     // alert(response.error.step);
          //     // alert(response.error.reason);
          //     // alert(response.error.metadata.order_id);
          //     // alert(response.error.metadata.payment_id);
          //   });
          //   const paymentObject = new window.Razorpay(options);
          //   paymentObject.open();
          // }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async loadRazorPay() {
      return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = this.script;
        script.onload = () => {
          resolve(true);
        };
        script.onerror = () => {
          resolve(false);
        };
        document.body.appendChild(script);
      });
    },
    closeModal() {
      var self = this;

      setTimeout(function () {
        self.hideModal();
      }, 2000);
    },
    hideModal() {
      this.modelShow = false;
    },

    getPaymentHistory(sellerId) {
      // console.log(sellerId);
      if (sellerId == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getPaymentHistoryAgainstSellerId(sellerId)
          .then((response) => {
            this.customerList = response.data.Data;
            this.initialLoading = false;
            //  this.params = response.data;
            // console.log(this.customerList);
          })
          .catch((e) => {
            console.log(e);
            this.initialLoading = false;
          });
      }
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      if (
        !this.$store.state.auth.user[0].sellerId == "" ||
        !this.$store.state.auth.user[0].sellerId == " "
      ) {
        this.$router.push("/PendingPayments").catch(() => {
          "home";
        });
      } else {
        this.$router.push("/").catch(() => {
          "home";
        });
      }
    }
  },
  filters: {
    currency: function (value) {
      if (typeof value !== "number") {
        return value;
      }
      let country = Config.countryCode;
      let crncy = Config.curency;
      let FractionDigits = Config.minFractionDigits;
      const formatter = new Intl.NumberFormat(country, {
        style: "currency",
        currency: crncy,
        minimumFractionDigits: FractionDigits,
      });
      return formatter.format(value);
    },
  },
};
</script>
<style scoped>
th label {
  margin-bottom: 0px;
}
table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}
table tr {
  background-color: #fff;
  border: 1px solid #e1e5e9;
  padding: 0.35em;
  border-radius: 3px;
}
table thead tr:first-child {
  border: 1px solid #0094da;
}
table th,
table td {
  padding: 8px;
  text-align: left;
  color: #202020;
  font-size: 14px;
}

table td:nth-child(4) {
  font-size: 18px;
}

table th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  background: #0094da;
  color: #fff;
}

table tbody tr td .btn-invoice {
  background: #0094da;
  color: #fff;
  font-size: 13px;
  padding: 10px 20px;
  border: 0;
  width: 100%;
  border-radius: 5px;
}

tbody tr:nth-child(even) {
  background-color: #eee;
}

tbody tr:nth-child(odd) {
  background-color: #fff;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }
  table caption {
    font-size: 1.3em;
  }
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    position: absolute;
    width: 1px;
    padding: 0;
  }
  table tr {
    border-bottom: 3px solid #e1e5e9;
    display: block;
    margin-bottom: 0.625em;
  }
  table th,
  table td {
    padding: 0.625em;
  }
  table td {
    border-bottom: 1px solid #e1e5e9;
    display: block;
    font-size: 0.8em;
    text-align: right;
    color: #9da9b9;
  }
  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
    color: #656971;
  }
  table td:last-child {
    border-bottom: 0;
  }
  table td:nth-child(4) {
    font-size: 0.8em;
  }
}
</style>
